// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'admin/variables';

// SB Admin Theme
@import '~startbootstrap-sb-admin/dist/css/styles.css';

//FontAwesome
@import '~@fortawesome/fontawesome-free/css/all.css';

//Custom Styles
@import "admin/all";

//Bootstrap-Migration v4 -> v5
@import "admin/migrate-bootstrap";

//Clearing
@import "admin/clearing";

//Receipt-Zooming
@import "admin/receiptzoom";
