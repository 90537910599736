.accordion-button {
    color: #858796 !important;
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 0.9rem !important;
    font-weight: 400 !important;
    line-height: 1.6;
    color: #858796 !important;
}

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: .5rem;
    }
}

.form-control, .form-select {
    font-size: .9rem;
    color: #6e707e;
}

.form-group {
    margin-bottom: 1rem;

    & > input[type="checkbox"] {
        display: block;
        width: 100%;
    }
}

td, th {
    color: #858796;
}

.image-filter-darken {
    filter: invert(1) brightness(0);
}
