.btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
}


.sidebar .nav-item .nav-link {
    padding: .5rem 1rem !important;
}
