@mixin status-item($name, $color) {
    [data-type="#{$name}"] {
        border-left: 5px solid $color;
    }

    [data-type-button="#{$name}"] {
        background-color: adjust-color($color, $lightness: +10%, $saturation: -20%);

        &:hover {
            background-color: adjust-color($color, $lightness: 0%, $saturation: -20%);

        }
    }
}

@include status-item("accept", green);
@include status-item("decline", red);
@include status-item("else", orange);

.bg-disabled {
    background-color: rgba(250, 250, 250, 0.9);
    filter: grayscale(.5) brightness(.9);
}

.table-success {
    --bs-table-bg: #2ecc71;
    --bs-table-striped-bg: #2ecc71;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #fff;
}

.table-primary {
    --bs-table-bg: #3498db;
    --bs-table-striped-bg: #3498db;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #fff;
}

.table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #f8d7da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #fff;
}

.table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #fff3cd;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #fff;
}
