.zoom-controls {
    display: block;
    position: fixed;
    top: 0;
    left: 0;

    padding: 1rem;
}

:root {
    --rotate: 0deg;
    --scale: 1;
    --posX: 0;
    --posY: 0;
}

.zoom-box {
    display: block;
    position: fixed;
    overflow: hidden;
    z-index: -1;

    * {
        cursor: move;
    }

    img {
        object-fit: contain;
    }

    .translate {
        transform: translate(var(--posX), var(--posY));
    }

    .scale-rotate {
        height: 100%;
        width: 100%;
        object-fit: contain;
        transform: rotate(var(--rotate)) scale(var(--scale));
        transition-duration: 100ms;
        z-index: -1;
    }
}
