@import "sb-admin";
@import "livewire-sortables";

//Clickable tale rows
td, th {
    & > .row-link {
        display: block;
        position: relative;
        margin: -.5rem;
        padding: .5rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-decoration: none;
        color: inherit;

        &:focus {
            outline: 0;
        }
    }
}

//Clearing images
.receipt-zoom {
    object-fit: cover;
    height: 150px;
}

.form-control-scrollable {
    overflow-y: auto;
    max-height: 10rem;
}
